/*----------  Global Variables  ----------*/

let wWidth;
let wHeight;

/*----------  Document Ready  ----------*/

$(document).ready(function() {
	wWidth = $(window).width();
	wHeight = $(window).height();

    console.log('Ready!');

    initSmoothScroll();
    fn_nav_toggle();
    initMatchHeight();
    fnCookieBar();
});

/*----------  Window Events  ----------*/

$(window).on('load resize', function() {
	wWidth = $(window).width();
	wHeight = $(window).height();
});

function initMatchHeight() {
    if (wWidth > 575) {
        $('.group_news').matchHeight({
            byRow: false
        });
    }
}

function fn_nav_toggle() {
    var target;

    var $toggler = $('[data-nav-toggle]');

    if ($toggler.length > 0 && wWidth < 768) {
        $toggler.off('click.toggle').on('click.toggle', function () {
            target = $(this).data('nav-toggle');
            var $target = $(target).first();

            if ($target.length > 0) {
                if ($target.hasClass('show')) {
                    $target.removeClass('show');
                    $target.find('.nav-item').removeClass('animate__animated animate__fadeInUp');
                    $('body').css('overflow', 'auto');
                    $toggler.removeClass('open');
                }
                else {
                    $target.addClass('show');
                    $target.find('.nav-item').addClass('animate__animated animate__fadeInUp');
                    $('body').css('overflow', 'hidden');
                    $toggler.addClass('open');
                }
            }

            return false;
        });
    }
}

function initSmoothScroll() {
    $('.smooth-scroll').on('click', function (event) {
        event.preventDefault();
        $('body, html').animate({
            scrollTop: $($(this).attr('href')).offset().top
        }, 1000);
    });

    $('.smoothScroll').on('click', function (event) {
        event.preventDefault();
        $('body, html').animate({
            scrollTop: $($(this).data('scrolltarget')).offset().top
        }, 1000);
    });
}

function fnCookieBar() {
    if (localStorage.getItem('cookieTerms') == 'true') {
        $('#cookieBar').hide();
    }
    $('#acceptCookieBtn').on('click', function () {
        localStorage.setItem("cookieTerms", true);
        $('#cookieBar').fadeOut();
    });
}
